import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import { BREAKPOINTS } from '../utils/variables';
import { checkViewPort } from '../utils/utils'

export default function titleSection() {
  const sections = gsap.utils.toArray('.js-title-section');
  let tweens = [];
  let isDestroy = checkViewPort(BREAKPOINTS.md);

  if (sections.length > 0) {
    const initAnimation = () => {
      sections.forEach((item, i) => {
        const title = item.querySelectorAll('.js-span');

        tweens[i] = gsap.to(title, {
          xPercent: -100,
          duration: 1,
          ease: 'none',
          scrollTrigger: {
            trigger: item,
            start: "top bottom",
            end: "=+100%",
            scrub: 1,
          }
        })
      })
    }

    const destroyAnimation = () => {
      tweens.forEach(tween => {
        tween.scrollTrigger.kill(true)
        tween.kill();
      });
      
      tweens = [];
    }

    if(!checkViewPort(BREAKPOINTS.md)) {
      initAnimation();
    }

    window.addEventListener('resize', () => {
      if(!checkViewPort(BREAKPOINTS.md) && isDestroy){
        isDestroy = false;
        initAnimation();
      } else if(checkViewPort(BREAKPOINTS.md) && !isDestroy && tweens.length > 0) {
        destroyAnimation();
        isDestroy = true;
      }
    })
  }
}
 