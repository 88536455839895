
export default function map() {

  const mapEl = document.querySelector('.js-map');

  if (!mapEl) return;

  const lat = mapEl.dataset.lat || 0;
  const lng = mapEl.dataset.lng || 0;


  ymaps.ready(() => {
    const map =  new ymaps.Map(mapEl, {
      center: [lat, lng],
      zoom: 16,
      controls: []
    });

    map.behaviors.disable('scrollZoom');
    map.behaviors.get('drag').disable();
    map.controls.add('zoomControl', {
			size: 'small',
		});

    map.geoObjects
		.add(new ymaps.Placemark([lat, lng], {
				balloonContent: '',
				iconCaption: ''
		}, {
				iconLayout: 'default#image',
				iconImageHref: '/assets/img/pin.svg',
				iconImageSize: [100, 100],
				iconImageOffset: [-50, -100]
		}));

  });
  
}
 