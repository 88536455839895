/*
 * @title App
 * @description Application entry point
 */

// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Components

import { getScrollbarWidth } from './utils/utils'

import MicroModal from 'micromodal';

import titleSection from 'Components/title-section';
import mainAnimate from 'Components/main-animate';
import video from 'Components/video';
import reviewsSlider from 'Components/reviews-slider';
import map from 'Components/map';
import linkTo from 'Components/linkTo';
import mobileMenu from 'Components/mobile-menu';
import forms from 'Components/forms';

document.addEventListener('DOMContentLoaded', function() {

  // Components

  mobileMenu();
  titleSection();
  mainAnimate();
  video();
  reviewsSlider();
  map();
  linkTo();
  forms();

  const modalLinks = document.querySelectorAll('.js-open-modal');

  modalLinks.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const href = link.getAttribute('href');

      MicroModal.show(href.split('#')[1], {
        onShow: () => {
          const scWidth = getScrollbarWidth();
          document.body.classList.add('_lock')
          document.body.style.paddingRight = `${scWidth}px`;
        },
        onClose: () => {
          document.body.classList.remove('_lock')
          document.body.style.paddingRight = ``;
        },
        disableFocus: true
      });
    })
  })
})
