const video = () => {
  const videoWrapper = document.querySelectorAll('.js-video');

  if (!videoWrapper) {
      return;
  }

  videoWrapper.forEach(videoWrap => {
    const video = videoWrap.children[0];

    video.addEventListener('click', () => {
      if (videoWrap.classList.contains('isPlay')) {
        videoWrap.classList.remove('isPlay');
        video.pause();
      } else {
        videoWrap.classList.add('isPlay');
        video.play();
      }
    });
  })
}

export default video;