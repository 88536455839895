
export default function mobileMenu() {
  const burger = document.querySelector('.js-burger');
  const mobilePane = document.querySelector('.js-mobile-pane');

  if (!burger || !mobilePane) return;

  burger.addEventListener('click', (e) => {
    e.preventDefault();

    if (!burger.classList.contains('_active')) {
      burger.classList.add('_active')
      mobilePane.classList.add('_active')
      document.body.classList.add('_lock')
    } else {
      burger.classList.remove('_active')
      mobilePane.classList.remove('_active')
      document.body.classList.remove('_lock')
    }
  })
}
 