import Swiper, { Autoplay } from 'swiper';
export default function reviewsSlider() {

  const slider = document.querySelector('.reviews-slider');

  if (!slider) return;

  new Swiper(slider, {
    modules: [ Autoplay ],
    slidesPerView: 'auto',
    freeMode: true,
    spaceBetween: 30,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 500,
      disableOnInteraction: false
    },
  });
  
}
 